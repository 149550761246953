import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler,
         HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { mergeMap, retryWhen, delay } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retries = 3;
    return next.handle(request).pipe(
      retryWhen((errors: Observable<any>) => {
        return errors.pipe(
          mergeMap(error => {
            if (error.status !== 0) {
              return throwError(error);
            } else {
              console.log(`Retrying request ${retries}`);
              return of(error);
            }
          }),
          delay(2000),
          mergeMap(error => retries-- > 0 ? of(error) : throwError(error))
        );
      })
    );
  }
}
