import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { AccountService } from './account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  @Input() dark: boolean;
  @Input() menu: MatMenu = null;

  constructor(public account: AccountService, public dialog: MatDialog) { }

  ngOnInit() { }
}
