import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { PROVIDERS } from '@lms/app/app.constant';

import get from 'lodash-es/get';

@Component({
    selector: 'lms-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

    copyright: string;
    refund_policy: string;

    facebook: string;
    telegram: string;
    linkedin: string;
    twitter: string;
    whatsapp: string;

    constructor() { }

    ngOnInit() {
        this.copyright = get(PROVIDERS[window.location.hostname], 'copyright');
        this.refund_policy = get(PROVIDERS[window.location.hostname], 'refund_policy');
        this.facebook = get(PROVIDERS[window.location.hostname], 'facebook');
        this.telegram = get(PROVIDERS[window.location.hostname], 'telegram');
        this.linkedin = get(PROVIDERS[window.location.hostname], 'linkedin');
        this.twitter = get(PROVIDERS[window.location.hostname], 'twitter');
        this.whatsapp = get(PROVIDERS[window.location.hostname], 'whatsapp');
    }

}
