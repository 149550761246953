export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyA1Xia725799awT7BTMZBVDQN0EW09VDRA',
    authDomain: 'osha-b65ae.firebaseapp.com',
    databaseURL: 'https://osha-b65ae.firebaseio.com',
    projectId: 'osha-b65ae',
    storageBucket: 'osha-b65ae.appspot.com',
    messagingSenderId: '536860153158'
  },
  google_drive: {
    driveConfig: {
      clientId: '536860153158-av63rqua5eq374u1um7bujh7ef9d2fbj.apps.googleusercontent.com',
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
      ],
      scope: 'https://www.googleapis.com/auth/drive.readonly'
    },
    APP_ID: '536860153158'
  },
  version: '0.0.6'
};
