import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountComponent } from './account.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccountService } from './account.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    AvatarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    AngularFireAuthModule
  ],
  providers: [
    AccountService
  ],
  exports: [
    AccountComponent
  ]
})
export class AccountModule { }
