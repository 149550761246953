import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../../../../src/app/pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'nioshcert',
    loadChildren: () => import('./pages/niosh/niosh.module').then(m => m.NioshModule)
  },
  {
    path: 'profile',
    loadChildren: () => import ('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'certificate',
    loadChildren: () => import ('./pages/certificate/certificate.module').then(m => m.CertificateModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'my-classes',
    loadChildren: () => import ('./pages/my-classes/my-classes.module').then(m => m.MyClassesModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'dashboard',
    loadChildren: () => import ('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'training',
    loadChildren: () => import ('./pages/training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'admin',
    loadChildren: () => import ('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly }
  },
  {
    path: 'qr-scanner',
    loadChildren: () => import ('./pages/qr-scanner/qr-scanner.module').then(m => m.QrScannerModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'refund-policy',
    loadChildren: () => import ('./pages/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule)
  },
  {
    path: '',
    redirectTo: 'training',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'training'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
