import { Component, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '@ui/app/components/account/account.service';
import { hasCustomClaim } from '@angular/fire/compat/auth-guard';
import { MediaObserver } from '@angular/flex-layout';
import { Router, NavigationStart, NavigationEnd, RoutesRecognized } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { PROVIDERS } from '@lms/app/app.constant';
import { LoadingService } from './app.loading.service';
import { delay, filter, pairwise } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import get from 'lodash-es/get';

@Component({
    selector: 'lms-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    admin$ = hasCustomClaim('admin');
    title = 'lms';
    /**
     * Header will stick on top when True
     */
    _static = true;
    _tools = false;
    _mode = 'side';
    _small = false;
    _menuSize = 250;
    _staticOpen = false;
    _burger = false;

    logo;
    logoHeight;
    favicon: HTMLLinkElement = document.querySelector('#favicon');

    loading = false;

    sideTemplate: TemplateRef<any>;
    @ViewChild('sidenav') sidenav: MatSidenav;

    urlStack = []; // for redirection back to page before login once authorized

    constructor(private change: ChangeDetectorRef,
                public account: AccountService,
                private media: MediaObserver,
                private router: Router,
                private loadingService: LoadingService,
                private titleService: Title) { }

    ngOnInit() {
        this.media.media$.subscribe(media => {
            if (media.mqAlias === 'xs' || media.mqAlias === 'sm') {
                this._mode = 'over';
            } else {
                this._mode = 'side';
            }
            if (media.mqAlias === 'xs') {
                this._small = true;
            } else {
                this._small = false;
            }
            this.change.markForCheck();
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.urlStack.push(event.url);
                if (this._mode === 'over') {
                    this.sidenav.close();
                }
            } else if (event instanceof NavigationEnd) {
                this.urlStack.pop();
                window.scrollTo(0, 0);
            }
        });

        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                const previousUrl =  events[0].urlAfterRedirects;
                sessionStorage.setItem('afterLogin', previousUrl);
            });

        this.account.user$.subscribe((user) => {
            setTimeout(() => {
                if (user && this.urlStack.length) {
                    // to redirect back to page before login once authorized
                    location.href = this.urlStack.pop();
                }
            }, 2000);
        });

        this.loadingService.loadingSub
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe((loading) => {
                this.loading = loading;
                this.change.markForCheck();
            });

        this.logo = get(PROVIDERS[window.location.hostname], 'logo', '/assets/image/proatoz.jpeg');
        this.logoHeight = get(PROVIDERS[window.location.hostname], 'logoHeight', '36px');
        this.favicon.href = get(PROVIDERS[window.location.hostname], 'favicon', '/assets/favicon.ico');
        this.titleService.setTitle(get(PROVIDERS[window.location.hostname], 'site_title', 'LMS | ProAToZ'));
    }

    defineSidebar(template: TemplateRef<any>) {
        this.sideTemplate = template;
        this.change.markForCheck();
    }

    pinHeader(flag: boolean) {
        this._static = flag;
    }

    showBurger(flag: boolean) {
        this._burger = flag;
    }

    signOut() {
        this.account.signOut().then(() => {
            location.href = '/';
        });
    }
}
