<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

  <a *ngIf="!(account.user$ | async) ; else avatar"
     mat-button 
     class="login-btn"
     [class.black-btn]="dark"
     routerLink="/login">
    <span>Sign In</span>
  </a>
  <ng-template #avatar>
    <ngx-avatar *ngIf="!menu"
                [src]="(account.user$ | async)?.photoURL"
                [name]="(account.user$ | async)?.displayName || (account.user$ | async)?.email">
    </ngx-avatar>

    <button *ngIf="menu" 
            class="account" 
            [matMenuTriggerFor]="menu">
      <ngx-avatar [src]="(account.user$ | async)?.photoURL"
                  [name]="(account.user$ | async)?.displayName || (account.user$ | async)?.email">
      </ngx-avatar>
    </button>
  </ng-template>
</div>
