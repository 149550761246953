export const PROVIDERS = {
    'lms.proatoz.com': {
        // provider_id: 2,
        logo: '/assets/image/proatoz.jpeg',
        logoHeight: '36px',
        copyright: 'Copyright © 2019 - 2020 ProAtoZ. All rights reserved. | Privacy Policy | Terms of Use',
        facebook: 'https://www.facebook.com/ProAtoZ/',
        telegram: 'https://t.me/Proatoz',
        training_heading: '<b>Training</b> Event'
    },
    'dev-lms.proatoz.com': {
        logo: '/assets/image/proatoz-dev.png',
        logoHeight: '36px',
        copyright: 'Copyright © 2019 - 2020 ProAtoZ. All rights reserved. | Privacy Policy | Terms of Use',
        facebook: 'https://www.facebook.com/ProAtoZ/',
        telegram: 'https://t.me/Proatoz',
        training_heading: '<b>Training</b> Event'
    },
    'nioshcert.proatoz.com': {
        provider_id: 1,
        logo: '/assets/image/niosh.jpg',
        logoHeight: '50px',
        favicon: '/assets/image/niosh-favicon.png',
        site_title: 'FLEX',
        copyright: '© Copyright 2018 NIOSHCert (641222-K) Proudly Created by <a target="_blank" href="https://proatoz.com">ProAtoZ</a>',
        facebook: 'https://www.facebook.com/nioshcertification/',
        linkedin: 'https://www.linkedin.com/company/niosh-certification-sdn-bhd-ncsb-/',
        training_heading: '<b>Focus Learning</b> Education Experience (FLEX)',
        admin_overall_dashboard_url: 'https://datastudio.google.com/embed/reporting/1c2769b8-01b2-4840-a04f-993619952bbf/page/PHQbB',
        admin_evaluation_dashboard_url: 'https://datastudio.google.com/embed/reporting/023e427f-09cf-4f36-a98d-0edb1628d74e/page/PHQbB'
    },
    'flex.nioshcert.com': {
        provider_id: 1,
        logo: '/assets/image/niosh.jpg',
        logoHeight: '50px',
        favicon: '/assets/image/niosh-favicon.png',
        site_title: 'FLEX',
        copyright: '© Copyright 2018 NIOSHCert (641222-K) Proudly Created by <a target="_blank" href="https://proatoz.com">ProAtoZ</a>',
        facebook: 'https://www.facebook.com/nioshcertification/',
        linkedin: 'https://www.linkedin.com/company/niosh-certification-sdn-bhd-ncsb-/',
        training_heading: '<b>Focus Learning</b> Education Experience (FLEX)',
        admin_overall_dashboard_url: 'https://datastudio.google.com/embed/reporting/1c2769b8-01b2-4840-a04f-993619952bbf/page/PHQbB',
        admin_evaluation_dashboard_url: 'https://datastudio.google.com/embed/reporting/023e427f-09cf-4f36-a98d-0edb1628d74e/page/PHQbB',
        enrollment_agreement: `
            <p>
                1. The information given is correct during publishing, The Organiser reserves the right to amend
                the Date, Rules & Regulations at any time, at its sole discretion, without any prior notice.
            </p>
            <p>
                2. Payment must be made before the course. All payments by company cheque shall be made payable
                to <b>NIOSH CERTIFICATION SDN. BHD.</b> and crossed “A/C PAYEE ONLY”. Any discrepancies must be promptly advised
                to our Accounts Department. Personal cheques will not be accepted.
            </p>

            <p>
                3. For payment by online banking or Telegraphic Transfer (TT), <b>NIOSH Certification Sdn Bhd</b>’s
                Maybank Berhad account number is <b>562263008072</b>.
            </p>

            <p>
                4. All cancellation by participants less than 14 DAYS from the course date is subject to be
                charged for 50% from the course fee, and any cancellation made less than 7 DAYS from the course date is subject to be
                charged the full amount of the course fee.
            </p>

            <p>
                5. By submitting this Form, you hereby agree that NIOSH Certification Sdn Bhd (NIOSHCert) may
                collect, obtain, store and process your personal data that you provide in this form for the purpose of receiving updates,
                news, promotional and marketing mails or materials from NIOSHCert.
            </p>

            <p>
                6. You hereby give your consent to NIOSHCert:-
                <ul>
                    <li>Store and process your Personal Data,</li>
                    <li>Disclose your Personal Data to the relevant governmental authorities or third parties where required by law or for legal purposes.</li>
                </ul>
            </p>

            <p>
                7. In addition, your personal data may be transferred to any company within the NIOSHCert which may involve sending your
                data to a location outside Malaysia. For the purpose of updating or correcting such data, you may at any time apply to
                the NIOSHCert to have access to your personal data which are stored by NIOSHCert.
                For the avoidance of doubt, Personal Data includes all data defined within the Personal Data
                Protection Act 2010 including all data you had disclosed to NIOSHCert in this Form.
            </p>
        `
    },
    'www.hseonlineacademy.com': {
        provider_id: 3,
        logo: '/assets/image/hse.png',
        logoHeight: '52px',
        copyright: `Copyright © HSE SOLUTIONS SDN BHD 1161760-U all rights reserved. <i>LMS Powered by <a target="_blank" href="https://proatoz.com">ProAtoZ</a></i><br><br>
            <b>HSE SOLUTIONS SDN BHD 1161760-U<br>
            HSE ENGINEERING AND CONSULTANCY SDN BHD 1222071-K</b><br>
            Prima 3, 135-1, Jalan S2 B21, Pusat Dagangan Seremban 2, 70300, Seremban Negri Sembilan.<br>
            <i class="mat-icon material-icons-outlined small-icon">phone</i> 013-9003599 (Syakila)&nbsp; 
            <i class="mat-icon material-icons-outlined small-icon">email</i> academy@sustainable-education.org`,
        facebook: 'https://www.facebook.com/hsegroupofcompanies',
        linkedin: 'https://www.linkedin.com/company/hsegroupofcompanies',
        twitter: 'https://twitter.com/hsegroupMY?s=08',
        whatsapp: 'https://wa.me/60139003599',
        training_heading: '<b>Training</b> Event',
        admin_overall_dashboard_url: 'https://datastudio.google.com/embed/reporting/8f058d71-e051-4669-9103-7cf227f83770/page/PHQbB',
        // admin_evaluation_dashboard_url: 'https://datastudio.google.com/embed/reporting/8bc7c1d7-a787-4569-bd1c-636da4a6e821/page/PHQbB',
        admin_evaluation_dashboard_url: 'https://datastudio.google.com/embed/reporting/d62c7a81-9ffa-463b-9991-4edfe8ce61a0/page/PHQbB',
        enrollment_agreement: `
            <p>
                1. The information given is correct during publishing, The Organiser reserves the right to amend
                the Date, Rules & Regulations at any time, at its sole discretion, without any prior notice.
            </p>

            <p>
                2. All cancellation by participants less than 14 DAYS from the course date is subject to be
                charged for 50% from the course fee, and any cancellation made less than 7 DAYS from the course date is subject to be
                charged the full amount of the course fee.
            </p>

            <p>
                3. By submitting this Form, you hereby agree that HSE Solution Sdn Bhd (HSE) may
                collect, obtain, store and process your personal data that you provide in this form for the purpose of receiving updates,
                news, promotional and marketing mails or materials from HSE.
            </p>

            <p>
                4. You hereby give your consent to HSE:-
                <ul>
                    <li>Store and process your Personal Data,</li>
                    <li>Disclose your Personal Data to the relevant governmental authorities or third parties where required by law or for legal purposes.</li>
                </ul>
            </p>

            <p>
                5. In addition, your personal data may be transferred to any company within the HSE which may involve sending your
                data to a location outside Malaysia. For the purpose of updating or correcting such data, you may at any time apply to
                the HSE to have access to your personal data which are stored by HSE.
                For the avoidance of doubt, Personal Data includes all data defined within the Personal Data
                Protection Act 2010 including all data you had disclosed to HSE in this Form.
            </p>
        `,
        refund_policy: `
            <p><b>Course Cancellations & Refunds</b></p>
            <p>► In house course</p>
            <p>By completing your registration for a course and making a payment of full or installment fee, you agree to the following Terms and Conditions:</p>
            
            <p>You have the right to cancel your booking at any time at the following terms:</p>
            
            <p>1.1 Any Classroom course cancelled after registration – admin fee of 25% of the course price applies (which will not be refunded), remaining deposit will be refunded. You need to request the course cancellation 5 working days before the course starting date.</p>
            
            <p>1.2 You are not eligible for any refund if you made a request for course cancellation less than 5 working days before the course starting date.</p>
            
            <p>1.3 1Training reserves the right to cancel a course if number of delegates have not reached the minimum required to run the course effectively. The company also reserves the right to cancel in the event of an unforeseen circumstance which disables a safe training environment. In the event of a cancellation, 1Training will either arrange for an alternative course date or offer a full refund of the fee paid.</p>
            
            <p>► Online Courses</p>
            <p>Under this refund policy and under guidance of the Malaysia's Consumer Protection Act 1999 , you may cancel your purchase of the course within the period of 14 calendar days (cancellation period) from the date on which the contract of purchase is concluded. Please note that, the right to cancel will not apply, after we issued your login detail for online study platform before receive your cancellation request to us.</p>
            
            <p>If you choose to cancel within this period you will receive a refund of the price within 30 days if you are eligible for refunds.</p>
            
            <p>If we posted any study material to you, you should return them to us before the refund.</p>
            
            <p>Please allow up to 2 working days to receive your online training details (Login details).</p>
            
            <p>Once a course has been redeemed, we cannot grant a refund. All refund requests can be sent to academy@sustainable-education.org</p>
            
            <p>► Course Postpone</p>
            <p>In house courses</p>
            
            <p>If you need to postpone your course, you need to make a request 5 working days before the course starting date and it does not cost for you. You are not able to make a request for course postpone less than 5 working days before the course starting date.</p>
            
            <p>► Refund Procedure</p>
            <p>1. Any refund will take take up to 30 days to process.</p>
        `
    },
};

export const STATES = {
    West: [
        { value: 'johor', name: 'Johor' },
        { value: 'kedah', name: 'Kedah' },
        { value: 'kelantan', name: 'Kelantan' },
        { value: 'malacca', name: 'Malacca' },
        { value: 'negeri_sembilan', name: 'Negeri Sembilan' },
        { value: 'pahang', name: 'Pahang' },
        { value: 'penang', name: 'Penang' },
        { value: 'perak', name: 'Perak' },
        { value: 'perlis', name: 'Perlis' },
        { value: 'selangor', name: 'Selangor' },
        { value: 'terengganu', name: 'Terengganu' }
    ],
    East: [
        { value: 'sabah', name: 'Sabah' },
        { value: 'sarawak', name: 'Sarawak' }
    ],
    Federal: [
        { value: 'kuala_lumpur', name: 'Kuala Lumpur' },
        { value: 'labuan', name: 'Labuan' },
        { value: 'putrajaya', name: 'Putrajaya' }
    ]
};

export const ZONES = Object.keys(STATES);

export const QUALIFICATIONS = [
    { name: 'SPM and Equivalent', value: 'spm' },
    { name: 'STPM and Equivalent', value: 'stpm' },
    { name: 'Diploma', value: 'diploma' },
    { name: 'Bachelor\'s Degree', value: 'degree' },
    { name: 'Master Degree', value: 'master' },
    { name: 'PHD', value: 'phd' },
    { name: 'Other', value: 'other' }
];

export const INDUSTRIES = [
    { name: 'Manufacturing', value: 'Manufacturing' },
    { name: 'Mining & Quarry', value: 'Mining & Quarry' },
    { name: 'Public Service & Authority', value: 'Public Service & Authority' },
    { name: 'Construction', value: 'Construction' },
    { name: 'Hotel & Restaurant', value: 'Hotel & Restaurant' },
    { name: 'Wholesale & Retail trade', value: 'Wholesale & Retail trade' },
    { name: 'Agriculture, Forestry& Aquaculture', value: 'Agriculture, Forestry& Aquaculture' },
    { name: 'Transportation, Warehouse & Communication', value: 'Transportation, Warehouse & Communication' },
    { name: 'Finance, Insurance, Real Estates & Business Service', value: 'Finance, Insurance, Real Estates & Business Service' },
    { name: 'Utilities (Electricity, Gas, Water & Sanitary Services)', value: 'Utilities (Electricity, Gas, Water & Sanitary Services)' },
    { name: 'Other', value: 'Other' }
];

export const SPONSORS = [
    { name: 'Self Sponsored', value: 'self' },
    { name: 'Company Sponsored', value: 'company' },
    { name: 'Other', value: 'other' },
];
