import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { AccountService } from '@ui/app/components/account/account.service';

if (environment.production) {
    Sentry.init({
        release: environment.version,
        dsn: 'https://46d8d528e5844c3c9d2e6b79503452dd@o305023.ingest.sentry.io/5237735'
    });
}

@Injectable({
    providedIn: 'root'
})
export class SentryErrorHandler extends ErrorHandler {

    constructor(private account: AccountService) {
        super();
    }

    handleError(error) {
        super.handleError(error);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
            return;
        }

        if (environment.production) {
            this.account.getMe().subscribe(user => {
                if (user) {
                    Sentry.setUser({
                        email: user.email,
                        id: user.uid
                    });

                    Sentry.captureException(error.originalError || error);
                }
            });
        }
    }
}
