<mat-toolbar class="lms-toolbar"
             [class.static_header]="_static" 
             [class.mat-elevation-z1]="_static" 
             style="background:white;z-index:10">
    <button mat-icon-button 
            class="menu-icon"
            (click)="sidenav.toggle()" 
            *ngIf="sideTemplate">
        <mat-icon fontSet="material-icons-outlined">menu</mat-icon>
    </button>
    <a mat-button routerLink='/'>
        <img [src]="logo" [style.height]="logoHeight" class="img_logo"/>
    </a>

    <div fxFlex></div>

    <app-account [dark]="true" [menu]="menu"></app-account>
</mat-toolbar>

<mat-sidenav-container style="min-height: 100%;">
    <mat-sidenav [mode]="_mode"
                 #sidenav
                 style="position:fixed" 
                 [class.small]="_small"
                 [style.width.px]="_menuSize">
        <ng-container *ngTemplateOutlet="sideTemplate"></ng-container>    
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" 
                        [class.offset_top]="_static"
                        [class.offset_left]="_staticOpen">
        <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate" style="position: fixed; z-index: 10;"></mat-progress-bar>
        <div fxFlex>
            <router-outlet></router-outlet>
        </div>
        <lms-footer></lms-footer>
    </mat-sidenav-content>
</mat-sidenav-container>

<div *lmsSidebar="account.user$ | async" style="height: 100%;">
    <mat-nav-list fxLayout="column" style="height: 100%;">
        <a mat-list-item [routerLink]="['training']" routerLinkActive="active">
            <mat-icon fontSet="material-icons-outlined">menu_book</mat-icon>
            <span>Training Event</span>
        </a>
        <mat-divider></mat-divider>
        <h3 mat-subheader>My Account</h3>
        <a mat-list-item [routerLink]="['dashboard']" routerLinkActive="active">
            <mat-icon fontSet="material-icons-outlined">home</mat-icon>
            <span>Dashboard</span>
        </a>
        <a mat-list-item [routerLink]="['profile']" routerLinkActive="active">
            <mat-icon fontSet="material-icons-outlined">person</mat-icon>
            <span>Profile</span>
        </a>
        <a mat-list-item [routerLink]="['my-classes']" routerLinkActive="active">
            <mat-icon fontSet="material-icons-outlined">event</mat-icon>
            <span>My Classes</span>
        </a>
        <a mat-list-item [routerLink]="['admin']" routerLinkActive="active"
           *ngIf="account.admin$ | async">
            <mat-icon fontSet="material-icons-outlined">vpn_key</mat-icon>
            <span>Admin</span>
        </a>

        <div fxFlex></div>

        <a mat-list-item [routerLink]="['qr-scanner']" routerLinkActive="active">
            <mat-icon fontSet="material-icons-outlined">qr_code_scanner</mat-icon>
            <span>Scan QR</span>
        </a>

     </mat-nav-list>
</div>

<mat-menu #menu="matMenu" class='menu-background'>
    <div fxLayout fxLayoutGap="24px" fxLayoutAlign="none center" class="menu-profile">
        <ngx-avatar size="60"
                [src]="(account.user$ | async)?.photoURL"
                [name]="(account.getMe() | async)?.name"></ngx-avatar>
        <div fxLayout="column">
            <b class="name">{{ (account.getMe() | async)?.name }}</b>
            <span class="email">{{ (account.getMe() | async)?.email }}</span>
            <button mat-raised-button color="primary" [routerLink]="['profile']">Update Profile</button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item [routerLink]="['my-classes']">
        <mat-icon fontSet="material-icons-outlined">event</mat-icon>
        <span>My Classes</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon fontSet="material-icons-outlined">exit_to_app</mat-icon>
        <span>Logout</span>
    </button>
  </mat-menu>