import { Injectable } from '@angular/core';
import { map, startWith, filter, shareReplay, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountService {
    user$: Observable<firebase.default.User>;
    admin$: Observable<boolean>;
    private me$: Observable<any>;

    constructor(private auth: AngularFireAuth, private http: HttpClient) {
        this.user$ = this.auth.authState;
        this.admin$ = this.auth.idTokenResult.pipe(
            filter(token => !!token),
            map(token => {
                const claims: any = token.claims;
                return claims && claims.admin;
            }),
            startWith(false)
        );
        this.auth.onAuthStateChanged(() => {
            this.clearMe();
        });
    }

    getMe() {
        if (!this.me$) {
            this.me$ = this.http.get('/api/accounts/me').pipe(
                catchError(() => {
                    return of(undefined);
                }),
                shareReplay(1)
            );
        }
        return this.me$;
    }

    clearMe() {
        this.me$ = null;
    }

    signOut() {
        const signout$ = this.auth.signOut();
        localStorage.removeItem('user');
        this.clearMe();
        return signout$;
    }
}
