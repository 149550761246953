<footer>
    <div class="container" fxLayout="column">
        <div fxLayout="row" fxLayout.lt-md="column-reverse" fxLayoutAlign="start center" fxLayoutGap="12px">
            <div [innerHTML]="copyright"></div>
            <div fxFlex></div>
            <div fxLayout="column" fxLayoutGap="24px">
                <a *ngIf="refund_policy" routerLink="/refund-policy">Refund Policy</a>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span style="margin-right:20px">Follow us:</span>
                    <a *ngIf="facebook" [href]="facebook" style="height:32px;">
                        <img src="assets/image/facebook.png" style="height:32px; background:white" />
                    </a>
                    <a *ngIf="telegram" [href]="telegram" style="height:32px;">
                        <img src="assets/image/telegram.png" style="height:32px; background:white" />
                    </a>
                    <a *ngIf="linkedin" [href]="linkedin" style="height:32px;">
                        <img src="assets/image/linkedin.png" style="height:32px; background:white" />
                    </a>
                    <a *ngIf="twitter" [href]="twitter" style="height:32px;">
                        <img src="assets/image/twitter.png" style="height:32px; background:white" />
                    </a>
                    <a *ngIf="whatsapp" [href]="whatsapp" style="height:32px;">
                        <img src="assets/image/whatsapp.png" style="height:32px; background:white" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>