import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler,
         HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.authState.pipe(
      take(1),
      mergeMap((user) => {
        console.log('auth', user);
        if ( user ) {
          return from(user.getIdTokenResult()).pipe(
            mergeMap((token) => {
              let headers = request.headers
                .set('X-Authorization', `firebase ${user.uid}`)
                .set('Authorization', `FirebaseToken ${token.token}`);
              if (window.localStorage.getItem('imposter')) {
                headers = headers
                  .set('X-Imposter', window.localStorage.getItem('imposter'));
              }
              const newReq = request.clone({ headers });
              return next.handle(newReq);
            })
          );
        } else {
          return next.handle(request);
        }
      })
    );
  }
}
