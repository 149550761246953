import { Directive, TemplateRef, Optional, Input, OnChanges, OnDestroy } from '@angular/core';
import { AppComponent as LmsComponent } from '@lms/app/app.component';

@Directive({
  selector: '[lmsSidebar]'
})
export class SidebarDirective implements OnChanges, OnDestroy {
  @Input() lmsSidebar: boolean;
  @Input() lmsSidebarStatic = true;

  constructor(private template: TemplateRef<any>,
              @Optional() private lms: LmsComponent) { }

  ngOnChanges() {
    if (this.lmsSidebar) {
      this.lms.defineSidebar(this.template);
    } else {
      this.lms.defineSidebar(null);
    }
  }

  ngOnDestroy() {
    this.lms.defineSidebar(null);
  }
}
