import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountModule } from '@ui/app/components/account/account.module';
import { FooterComponent } from '@lms/app/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { environment } from '@lms/environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AuthInterceptor } from '@ui/app/auth.interceptor';
import { AvatarModule } from 'ngx-avatar';
import { SidebarDirective } from './directive/sidebar.directive';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { SentryErrorHandler } from '@lms/app/error.handler.service';
import { RetryInterceptor } from '@ui/app/retry.interceptor';
import { LoadingService } from '@lms/app/app.loading.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingInterceptor } from './app.loading.interceptor';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        SidebarDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatProgressBarModule,
        BrowserAnimationsModule,
        AccountModule,
        HttpClientModule,
        FlexLayoutModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
        MatListModule,
        AvatarModule,
        AccountModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule
    ],
    providers: [
        HttpClientModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        AngularFireAuthGuard,
        LoadingService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
